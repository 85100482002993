function Accordion() {
    const accordions = Array.from(document.querySelectorAll('.c-accordion__title'));
    accordions.forEach(setupAccordions);
}

function setupAccordions(title) {
    title.addEventListener('click', titleClick())
}

function titleClick() {
    return (event) => {
        var item = event.currentTarget.parentElement;
        item.classList.toggle('c-accordion__item--open');
    }
}

export default Accordion;
