// Function to initialize off-canvas behavior
function OffCanvas() {
  // Select all elements that trigger off-canvas behavior
  const toggles = document.querySelectorAll(".c-off-canvas__toggle");

  // Iterate through each toggle element
  if (toggles.length) {
    toggles.forEach(toggle => {
      // Determine initial visibility state and handle click event
      handleOffCanvas(toggle, isVisible(toggle));
      toggle.addEventListener('click', () => toggleOffCanvas(toggle));
    });

    // Add event listener for scroll events to check visibility
    // window.addEventListener('scroll', () => {
    //   toggles.forEach(toggle => {
    //     handleOffCanvas(toggle, isVisible(toggle));
    //   });
    // });

    // Add event listener for resize events to check visibility
    window.addEventListener('resize', () => {
      toggles.forEach(toggle => {
        handleOffCanvas(toggle, isVisible(toggle));
      });
    });
  }
}

// Function to check if an element is currently visible on the page
function isVisible(element) {
  // Check element's styles for visibility
  const style = window.getComputedStyle(element);
  return (
    style.display !== 'none' &&
    style.visibility !== 'hidden'
  );
}

// Function to handle off-canvas behavior based on visibility
function handleOffCanvas(toggle, isVisible) {
  const target = document.getElementById(toggle.getAttribute('data-target'));
  if (isVisible === true) {
    createOffCanvas(target);
  } else {
    removeOffCanvas(target);
  }
}

// Function to create off canvas
function createOffCanvas(target) {
  // Add necessary classes and attributes for off-canvas display
  target.classList.add('c-off-canvas');
  target.setAttribute('tabindex', '-1');
  target.setAttribute('aria-hidden', 'true');
  
  // Extract specific sections of the off-canvas content
  const targetHeader = target.querySelector('[data-off-canvas="header"]');
  const targetMain = target.querySelector('[data-off-canvas="main"]');
  const targetFooter = target.querySelector('[data-off-canvas="footer"]');

  // Extract content from the sections
  const targetHeaderContent = targetHeader !== null ? targetHeader.outerHTML : '';
  const targetMainContent = targetMain !== null ? targetMain.outerHTML : '';
  const targetFooterContent = targetFooter !== null ? targetFooter.outerHTML : '';

  // Replace the target's content with off-canvas structure
  target.innerHTML = `
    <div class="c-off-canvas__backdrop" role="presentation"></div>
    <div class="c-off-canvas__content" role="dialog" aria-modal="true" aria-labelledby="offCanvasHeader">
      <header id="offCanvasHeader" class="c-off-canvas__header">${targetHeaderContent}</header>
      <main class="c-off-canvas__main">${targetMainContent}</main>
      <footer class="c-off-canvas__footer">
        ${targetFooterContent}
        <button class="mp c-twi c-twi--left c-off-canvas__close" data-off-canvas-close="true" onclick="return false">
          <span>Cancel</span>
          <svg role="img" aria-hidden="true" focusable="false" class="mp c-icon c-icon--cross">
            <use xlink:href="/static/svg/sprite.svg#cross"></use>
          </svg>
        </button>
      </footer>
    </div>
  `;
}

// Function to remove off-canvas layout
function removeOffCanvas(target) {
  // Remove classes and attributes for off-canvas display
  target.classList.remove('c-off-canvas', 'c-off-canvas--active');
  target.removeAttribute('tabindex');
  target.removeAttribute('aria-hidden');

  // Remove backdrop element if present
  const targetBackdrop = target.querySelector('.c-off-canvas__backdrop');
  if (targetBackdrop) {
    targetBackdrop.remove();
  }

  // Restore original content by removing off-canvas structure
  const targetContent = target.querySelector('.c-off-canvas__content');
  if (targetContent) {
    while (targetContent.firstChild) {
      target.insertBefore(targetContent.firstChild, targetContent);
    }
    target.removeChild(targetContent);
  }

  const targetHeader = target.querySelector('.c-off-canvas__header');
  if (targetHeader) {
    while (targetHeader.firstChild) {
      target.insertBefore(targetHeader.firstChild, targetHeader);
    }
    target.removeChild(targetHeader);
  }

  const targetMain = target.querySelector('.c-off-canvas__main');
  if (targetMain) {
    while (targetMain.firstChild) {
      target.insertBefore(targetMain.firstChild, targetMain);
    }
    target.removeChild(targetMain);
  }

  const targetFooter = target.querySelector('.c-off-canvas__footer');
  if (targetFooter) {
    while (targetFooter.firstChild) {
      target.insertBefore(targetFooter.firstChild, targetFooter);
    }
    target.removeChild(targetFooter);
  }
  
  // Remove close button element if present
  const targetClose = target.querySelector('.c-off-canvas__close');
  if (targetClose) {
    targetClose.remove();
  }
}

// Function to toggle off-canvas visibility
function toggleOffCanvas(toggle) {
  const target = document.getElementById(toggle.getAttribute('data-target'));

  // Check if the target is already open or closed
  if (!target.classList.contains('c-off-canvas--active')) {
    openOffCanvas(toggle, target);
  } else if (target.classList.contains('c-off-canvas--active')) {
    closeOffCanvas(target);
  }
}

function openOffCanvas(toggle, target) {
  // Add classes and attributes for open off-canvas
  toggle.setAttribute('aria-expanded', 'true');
  target.classList.add('c-off-canvas--active');
  target.setAttribute('aria-hidden', 'false');

  // Add event listener for backdrop clicks
  const offCanvasBackdrop = target.querySelector('.c-off-canvas__backdrop');
  if(offCanvasBackdrop) {
    offCanvasBackdrop.addEventListener('click', handleBackdropClick);
  }

  // Add event listener forclose button clicks
  const offCanvasCloseElements = target.querySelectorAll('[data-off-canvas-close="true"]');
  if (offCanvasCloseElements.length > 0) {
    offCanvasCloseElements.forEach((offCanvasClose) => {
      offCanvasClose.addEventListener('click', handleCloseClick);
    });
  }

   // Add event listener for Escape key press
  document.addEventListener('keydown', handleKeyDown, { once: true });

  // Adjust body overflow to prevent scrolling
  document.body.style.overflowY = 'hidden';

  // Close other open off-canvas elements
  const allOffCanvasElementsExceptCurrent = Array.from(document.querySelectorAll('.c-off-canvas')).filter(offCanvas => offCanvas !== target);
  if (allOffCanvasElementsExceptCurrent.length > 0) {
    allOffCanvasElementsExceptCurrent.forEach((offCanvas) => {
      closeOffCanvas(offCanvas);
    });
  }
}

// Function to close an off-canvas
function closeOffCanvas(target) {
  // Remove classes and attributes for open off-canvas
  document.querySelector('[data-target="' + target.id + '"').setAttribute('aria-expanded', 'false');
  target.classList.remove('c-off-canvas--active');
  target.setAttribute('aria-hidden', 'true');

  // Restore body overflow
  document.body.style.overflowY = '';
}

// Function to handle backdrop click
function handleBackdropClick() {
  const offCanvas = this.closest('.c-off-canvas');
  closeOffCanvas(offCanvas);
}

// Function to handle Escape key press
function handleKeyDown(event) {
  if (event.key === 'Escape') {
    const activeOffCanvas = document.querySelector('.c-off-canvas--active');
    if (activeOffCanvas) {
      closeOffCanvas(activeOffCanvas);
    }
  }
}

// Function to handle close button click
function handleCloseClick() {
  const offCanvas = this.closest('.c-off-canvas');
  closeOffCanvas(offCanvas);
}

export default OffCanvas;