import { debounce } from './utilities';

function ScrollbarWidth() {
    setScrollbarWidth();
    window.addEventListener('resize', debounce(setScrollbarWidth, 250));
}

function setScrollbarWidth() {
    document.documentElement.style.setProperty(
        '--scrollbarWidth', 
        window.innerWidth - document.body.clientWidth + 'px'
    );
}

export default ScrollbarWidth;