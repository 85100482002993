function Carousel() {
  let current = 0;
  const bgSlides = document.querySelectorAll('.c-hero__image-bg');
  const fgSlides = document.querySelectorAll('.c-hero__image-fg');

  if(fgSlides.length > 0) {
    setInterval(() => {
      for (var bgSlide of bgSlides) {
        bgSlide.style.opacity = 0;
      }
      current = (current != bgSlides.length - 1) ? current + 1 : 0;
      bgSlides[current].style.opacity = 1;
      
      for (var fgSlide of fgSlides) {
        fgSlide.style.opacity = 0;
      }
      fgSlides[current].style.opacity = 1;
    }, 5000);
  }
}

export default Carousel;