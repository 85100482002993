function Tabs() {
  const tabs = Array.from(document.querySelectorAll('.c-tabs--anchor'));
  tabs.forEach(setupTabs);
}

function setupTabs(parent) {
  const controls = Array.from(parent.querySelectorAll('.c-tabs__control'));
  const content = Array.from(parent.querySelectorAll('.c-tab'));
  const persist = parent.classList.contains('c-tabs--persist');
  
  if (!controls.length || !content.length) return;

  controls.forEach(control => {
    control.addEventListener('click', controlClick(controls, content, persist))
  })

  if (persist && window.location.hash) {
    const control = parent.querySelector('[href="' + window.location.hash + '"]');
    if (control) control.dispatchEvent(new Event('click'));
  }
}

function controlClick(controls, contents, persist) {
  return (event) => {
    const control = event.currentTarget;
    const content = contents.find(x => x.id === control.getAttribute('href').replace('#', ''));
    
    if (!content) {
      return true;
    };

    event.preventDefault();
    
    if (persist) {
      history.pushState(null, null, control.getAttribute('href'));
    }

    controls.forEach(c => c.classList.toggle('c-tabs__control--active', c === control));
    contents.forEach(c => {
      const isCurrent = c === content;
      c.classList.toggle('c-tab--active', isCurrent);
      
      if (persist) return;

      if (isCurrent) {
        c.setAttribute('tabIndex', -1);
        
        const y = window.scrollY;
        c.focus();
        window.scrollTo({ behavior: "auto", top: y })
      } else {
        c.removeAttribute('tabIndex');
      }
    });
  }
}

export default Tabs;
